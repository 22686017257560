@import 'src/utils/utils';

.togglebox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 13px;

  &__value {
    @include Text-14-bold;
    margin: 0;
    flex-shrink: 0;
  }

  &__label {
    cursor: pointer;

    input {
  
      &:checked {
  
        & + .togglebox__box .togglebox {

          &__box-center {
            transform: translateX(22px);
          }

          &__box-circle {

            &--left {
              transform: scale(0);
            }

            &--right {
              transform: scale(1);
            }
          }
        }
      }
    }
  }

  &__box {
    position: relative;
    display: flex;
    width: 50px;
    height: 26px;
    justify-content: space-between;
    align-items: center;
    padding-top: 3px;
    padding-right: 4px;
    padding-bottom: 3px;
    padding-left: 4px;
    background-color: $Corporate-2;
    border-radius: 220px;
  }

  &__box-center {
    position: absolute;
    width: 20px;
    height: 10px;
    background-color: $Main-2;
    border-radius: 220px;
    transform-origin: center;
    transition: transform 0.2s ease-out;
  }

  &__box-circle {
    width: 20px;
    height: 20px;
    background-color: $Main-2;
    border-radius: 50%;
    backface-visibility: hidden;
    transition: transform 0.2s;

    &--left {
      transform-origin: center right;
      transform: scale(1);
    }

    &--right {
      transform-origin: center left;
      transform: scale(0);
    }
  }
}